.listitem {
  &_img-event {
    height: 200px;
    background-image: url(../assets/images/blog1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;

    @media only screen and (max-width: $bp-large) {
      height: 120px;
    }
  }
}
