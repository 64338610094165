.contact {
  &_page {
    &_container {
      padding-top: 80px;
      padding-left: 120px;
      padding-right: 120px;
      overflow-y: auto;
      overflow-x: hidden;
      height: 90%;
      @media only screen and (max-width: $bp-smallest) {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 50px;
      }
      &_contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 3.125rem;
        @media only screen and (max-width: $bp-smallest) {
          margin-bottom: 50px;
        }
      }
    }
  }
  &_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4.375rem;
    @media only screen and (max-width: $bp-smallest) {
      display: inline;
    }
    &_leftaligned {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      @media only screen and (max-width: $bp-smallest) {
        padding-left: 20px;
      }
    }
  }
  &_dropdown {
    max-width: 250px;
  }
  &_rows {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: $bp-smallest) {
      flex-direction: column;
    }
  }
  &_textarea {
    max-width: 500px;
    @media only screen and (max-width: $bp-smallest) {
      max-width: 250px;
    }
  }
  &_button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 30px;
    background-color: #0b659b;
    //  background-color: '#0B659B';
    align-self: 'center';
    border: 0px;
    border-radius: 3px;
    color: white;
    @media only screen and (max-width: $bp-smallest) {
      margin-left: 20px;
    }
  }
}

.para {
  &_bigboldest {
    font-size: 28px;
    font-weight: 800;
    @media only screen and (max-width: $bp-smallest) {
      max-width: 170px;
      white-space: pre-wrap;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }
  &_bold {
    font-size: 14px;
    font-weight: 800;
    @media only screen and (max-width: $bp-smallest) {
      max-width: 170px;
      white-space: pre-wrap;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
    }
  }
  &_regular {
    font-size: 48px;
    font-weight: 800;
  }
  &_dullsmall {
    font-size: 10px;
    font-weight: 600;
    color: #060606b0;
    opacity: 0.6;
  }
}

.space {
  &_top {
    &_largest {
      margin-top: 25px;
    }
    &_large {
      margin-top: 20px;
    }
    &_medium {
      margin-top: 15px;
    }
    &_small {
      margin-top: 10px;
    }
    &_smallest {
      margin-top: 5px;
    }
  }
  &_bottom {
    &_largest {
      margin-bottom: 25px;
    }
    &_large {
      margin-bottom: 20px;
    }
    &_medium {
      margin-bottom: 15px;
    }
    &_small {
      margin-bottom: 10px;
    }
    &_smallest {
      margin-bottom: 5px;
    }
  }
  &_left {
    &_largest {
      margin-left: 25px;
    }
    &_large {
      margin-left: 20px;
    }
    &_medium {
      margin-left: 15px;
    }
    &_small {
      margin-left: 10px;
    }
    &_smallest {
      margin-left: 5px;
    }
  }
  &_right {
    &_largest {
      margin-right: 25px;
    }
    &_large {
      margin-right: 20px;
    }
    &_medium {
      margin-right: 15px;
    }
    &_small {
      margin-right: 10px;
    }
    &_smallest {
      margin-right: 5px;
    }
  }
}
