.blog {
  &_container {
    margin-top: 120px;
    padding-left: 150px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    @media only screen and (max-width: $bp-large) {
      padding-left: 50px;
    }
    @media only screen and (max-width: $bp-medium) {
      padding-left: 0px;
    }
    @media only screen and (max-width: $bp-small) {
      margin: 15% 5%;
    }
    @media only screen and (max-width: $bp-smallest) {
      margin: 20% 5% 15% 5%;
    }
  }
}

.listitem {
  &_container {
    display: flex;
    //  flex-direction: row;
    margin-right: 100px;
    margin-bottom: 100px;
    :hover {
      cursor: pointer;
    }
    @media only screen and (max-width: $bp-large) {
      margin-right: 40px;
    }
    @media only screen and (max-width: $bp-medium) {
      margin-right: 0px;
    }
    @media only screen and (max-width: $bp-small) {
      margin-bottom: 30px;
      width: 100%;
    }
  }
  &_img-blog {
    height: 120px;
    width: 120px;
    border-color: red;
    @media only screen and (max-width: $bp-small) {
      height: 100px;
      width: 100px;
      min-width: 100px;
      min-height: 100px;
      max-width: 100px;
      max-height: 100px;
    }
  }
  &_content {
    width: 300px;
    margin-left: 10px;
    @media only screen and (max-width: $bp-medium) {
      width: 100px;
      margin-left: 5px;
    }

    &_title {
      font-weight: bold;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      // width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: justify;
      @media only screen and (max-width: $bp-small) {
        font-size: 16px;
        margin-top: 0px;
      }
    }
    &_desc {
      // min-width: 200px;
      margin-top: 10px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.76);
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 60px;
      display: -webkit-box;
      width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-align: justify;
      // white-space: nowrap;
      @media only screen and (max-width: $bp-large) {
        font-size: 13px;
      }
      @media only screen and (max-width: $bp-small) {
        font-size: 13px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.55);
        margin-top: 5px;
      }
    }
    &_schedule {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      @media only screen and (max-width: $bp-large) {
        font-size: 13px;
      }
      @media only screen and (max-width: $bp-small) {
        font-size: 10px;
        font-weight: 600;
        margin-top: 5px;
      }
    }
    @media only screen and (max-width: $bp-large) {
      max-width: 250px;
    }
    @media only screen and (max-width: $bp-small) {
      width: 100%;
    }
  }
}

.para {
  &_container {
    height: 100%;
    overflow-y: auto;
    padding: 25px 300px 150px 300px;
    display: flex;
    flex-direction: column;
    //  justify-content: center;
    margin-top: 75px;
    align-items: center;
    @media only screen and (max-width: $bp-gigantic) {
      padding: 25px 13%;
    }
    @media only screen and (max-width: $bp-huge) {
      padding: 25px 10%;
    }
    @media only screen and (max-width: $bp-large) {
      padding: 25px 0px;
    }
    @media only screen and (max-width: $bp-small) {
      padding: 25px 0px 50px 0px;
    }
  }
  &_wrapper {
    max-width: 70%;
    @media only screen and (max-width: $bp-large) {
      max-width: 85%;
    }
    @media only screen and (max-width: $bp-small) {
      max-width: 90%;
    }
  }
  &_title {
    width: 100%;
    //  max-width: 800px;
    //  height: 173px;
    opacity: 0.8;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    font-family: 'Lora', serif;
    @media only screen and (max-width: $bp-small) {
      // width: 374px;
      // height: 51px;
      font-size: 20px;
      font-weight: bold;
    }

    &_tag {
      width: 100%;
      // height: 28px;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.71px;
      text-align: left;
      color: rgba(0, 0, 0, 0.65);
      margin-top: 10px;
    }
    &_datetime {
      width: 100%;
      // height: 19px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.48px;
      margin-top: 10px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  &_image {
    margin-top: 20px;
    //  height: 100vh;
    width: 100%;
    padding: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% center;
    background-color: #012c5c;
    background-image: url(../assets/background/web_home@2x.png);

    /* background-image: url('https://images.unsplash.com/photo-1505751172876-fa1923c5c528?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'); */
  }
  &_section {
    width: 100%;
    //  height: 185px;
    //  opacity: 0.7;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.84);
    margin-top: 20px;
    text-align: justify;
    font-family: 'Noto Serif', serif;
    @media only screen and (max-width: $bp-small) {
      // width: 374px;
      // height: 98px;
      font-size: 16px;
    }

    &_header {
      width: 100%;
      // height: 51px;
      opacity: 0.9;
      font-size: 38px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      margin-top: 80px;
      @media only screen and (max-width: $bp-small) {
        //   width: 241px;
        //   height: 25px;
        font-size: 21px;
      }
    }
  }
  &_styled {
    width: 100%;
    //  opacity: 0.9;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: 0.6px;
    text-align: left;
    color: rgba(0, 0, 0, 0.84);
    font-style: italic;
    @media only screen and (max-width: $bp-small) {
      width: 331px;
      opacity: 0.7;
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      line-height: 2.17;
      text-align: center;
    }
    &_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
      margin-top: 20px;
      // max-width: 600px;
      padding: 0 10%;
    }
    &_center {
      text-align: center;
    }
    &_left {
      text-align: left;
    }
    &_right {
      text-align: right;
    }
    &_paddingright {
      padding-right: 30px;
    }
    &_paddingleft {
      padding-left: 30px;
    }
    &_lineheight {
      line-height: 30px;
    }
  }
  &_list {
    width: 100%;
    //  height: 185px;
    //  opacity: 0.7;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.84);
    margin-top: 5px;
    text-align: justify;
    list-style-position: inside;
    font-family: 'Noto Serif', serif;
    @media only screen and (max-width: $bp-small) {
      // width: 374px;
      // height: 98px;
      font-size: 14px;
      // margin-top: 5px;
    }
  }
}

.watermark {
  font-size: 5.125rem;
  font-weight: 'bolder';
  opacity: 0.25;
  margin-top: '20%';
  @media only screen and (max-width: $bp-huge) {
    font-size: 5.125rem;
    margin-top: '25%';
  }
  @media only screen and (max-width: $bp-large) {
    font-size: 3.75rem;
    margin-top: '30%';
  }
  @media only screen and (max-width: $bp-small) {
    font-size: 2.5rem;
    margin-top: '40%';
  }
}
