@import './SVariables.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: 'Open Sans';
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
  min-width: 300px;

  @media only screen and (orientation: portrait) {
    width: 100%;
  }

  @media only screen and (orientation: landscape) {
    height: 100%;
  }
}

.ovrly {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 8vh; /* Full height (cover the whole page) */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0); /* Black background with opacity */
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_hdr {
    top: 0;
    &_blog {
      // background-color: #ebecef; /* Black background with opacity */
      background-color: rgba(235, 236, 239, 1); /* Black background with opacity */
      z-index: 999;
    }
  }
  &_ftr {
    bottom: 0;
    &_blog {
      background-color: rgba(235, 236, 239, 1); /* Black background with opacity */
    }
  }
}

.navlinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  font-size: 12px;
  font-weight: 600;
  opacity: 1;
  margin-right: 30px;
  font-family: 'Open Sans';
  letter-spacing: 1px;

  &_hdr {
    //  padding-top: 10px;
    width: 20%;
    min-width: 199px;
    @media only screen and (max-width: $bp-smallest) {
      min-width: 170px;
      font-size: 10px;
    }
  }
  &_ftr {
    //  padding-bottom: 10px;
    width: 5%;
    min-width: 70px;
    font-size: 1rem;
    @media only screen and (max-width: $bp-smallest) {
      font-size: 0.875rem;
      width: 3%;
      min-width: 2.5rem;
    }
    &_large {
      width: 20%;
      @media only screen and (max-width: $bp-large) {
        width: 25%;
      }
      @media only screen and (max-width: $bp-medium) {
        width: 30%;
      }
      @media only screen and (max-width: $bp-small) {
        width: 40%;
      }
      @media only screen and (max-width: $bp-smallest) {
        width: 50%;
      }
      @media only screen and (max-width: $bp-tiny) {
        width: 70%;
      }
    }
  }
  &_paddingleft {
    padding-left: 20px;
  }
}

.navStyle {
  color: #ffffff;
  text-decoration: none;
  &_icon {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 'bold';
    margin-left: 30px;
    line-height: 12px;
    font-size: 16px;
    @media only screen and (max-width: $bp-smallest) {
      font-size: 14px;
    }
  }
  &_blog {
    color: #000;
    &_hide {
      // display: none;
      visibility: hidden;
    }
  }
  &_hdr {
    //  padding-top: 10px;
  }
  &_ftr {
    //  padding-bottom: 10px;
  }
}

// Home page

.bg {
  height: 100vh;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% center;
  background-color: #012c5c;
  display: flex;
  flex-direction: column;
  &_home {
    background-image: url(../assets/background/web_home@2x.png);
    @media only screen and (max-width: $bp-smallest) {
      background-image: url(../assets/background/mob_home@2x.png);
    }
  }
  &_musician {
    background-image: url(../assets/background/web_musician@2x.png);
    @media only screen and (max-width: $bp-smallest) {
      background-image: url(../assets/background/mob_musician@2x.png);
    }
  }
  &_doctor {
    background-image: url(../assets/background/web_doctor.png);
    @media only screen and (max-width: $bp-smallest) {
      background-image: url(../assets/background/mob_doctor@2x.png);
    }
  }
  &_author {
    background-image: url(../assets/background/web_author@2x.png);
    @media only screen and (max-width: $bp-smallest) {
      background-image: url(../assets/background/mob_author@2x.png);
    }
  }
  &_blog {
    background-color: #ffffff;
    background-image: url(../assets/background/web_blog@2x.png);
    @media only screen and (max-width: $bp-smallest) {
      background-image: url(../assets/background/mob_blog@2x.png);
    }
  }
  &_contact {
    background-color: #ffffff;
    background-image: url(../assets/background/web_contact@2x.png);
    @media only screen and (max-width: $bp-smallest) {
      background-image: url(../assets/background/mob_contact@2x.png);
    }
  }
}

.page {
  &_container {
    overflow-y: auto;
    height: 100%;
    padding-left: 120px;
    &_top {
      padding-top: 100px;
      padding-left: 120px;
      @media only screen and (max-width: $bp-gigantic) {
        padding-top: 60px;
      }
      @media only screen and (max-width: $bp-large) {
        padding-left: 30px;
        //   padding-top: 0px;
      }
      @media only screen and (max-width: $bp-medium) {
        padding-left: 20px;
        //   padding-top: 100px;
      }
      @media only screen and (max-width: $bp-small) {
        padding-top: 0px;
        padding-left: 5px;
      }
    }
    &_bottom {
      padding-top: 250px;
      padding-left: 120px;
      @media only screen and (max-width: $bp-gigantic) {
        padding-top: 60px;
      }
      @media only screen and (max-width: $bp-large) {
        padding-left: 30px;
        padding-top: 150px;
      }
      @media only screen and (max-width: $bp-medium) {
        padding-left: 20px;
        padding-top: 30px;
      }
      @media only screen and (max-width: $bp-small) {
        padding-top: 100px;
        padding-left: 5px;
      }
    }
    &_contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &_title {
    &_hdr {
      padding-top: 170px;
      // padding-left: 120px;
      max-height: 107px;
      font-family: 'Open Sans';
      font-size: 79px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.75;
      letter-spacing: 2.69px;
      color: #ffffff;
      white-space: nowrap;
      @media only screen and (max-width: $bp-large) {
        font-size: 40px;
      }
      @media only screen and (max-width: $bp-medium) {
        font-size: 40px;
        letter-spacing: 1.22px;
        padding-left: 40px;
        padding-top: 300px;
      }
      @media only screen and (max-width: $bp-smallest) {
        font-size: 30px;
        letter-spacing: 1.22px;
        padding-left: 25px;
        padding-top: 160px;
      }
    }
    &_content {
      padding-top: 90px;
      // padding-left: 120px;
      max-width: 600px;
      max-height: 147px;
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.68px;
      text-align: left;
      color: #b9bec9;

      @media only screen and (max-width: $bp-large) {
        padding-top: 50px;
      }

      @media only screen and (max-width: $bp-medium) {
        min-width: 250px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 50px;
        font-size: 14px;
        line-height: 1.9;
      }
      @media only screen and (max-width: $bp-smallest) {
        min-width: 250px;
        padding-left: 25px;
        padding-right: 50px;
        padding-top: 50px;
        font-size: 11px;
        line-height: 1.9;
      }
    }
  }
}

.direction {
  &_row {
    flex-direction: row;
  }
  &_column {
    flex-direction: column;
  }
}

.carousel {
  &_container {
    /* position: relative;
    margin-right: 20px;
    border-radius: 100px; */

    position: absolute;
    margin-right: 20px;
    top: 34.375rem;
    //  left: 100px;
    border-radius: 100px;
    width: 85%;
    //  height: 200px;
    align-self: center;

    @media only screen and (max-width: $bp-gigantic) {
      top: 500px;
      // left: 100px;
    }
    @media only screen and (max-width: $bp-large) {
      top: 400px;
    }

    @media only screen and (max-width: $bp-medium) {
      top: 500px;
      // left: 100px;
    }
    @media only screen and (max-width: $bp-smallest) {
      top: 350px;
      // left: 100px;
    }
  }
}

.container {
  position: relative;
  margin-right: 20px;
  border-radius: 100px;
  //   width: 50%;
}

.img {
  display: block;
  width: 100%;
  height: auto;
  @media only screen and (max-width: $bp-mammoth) {
    width: 60%;
  }
}
.image {
  display: block;
  width: 100%;
  height: auto;
  &_carousel {
    width: 18.75rem;
    height: auto;
    @media only screen and (max-width: $bp-gigantic) {
      width: 18.75rem;
    }
    @media only screen and (max-width: $bp-large) {
      width: 18.75rem;
    }

    @media only screen and (max-width: $bp-medium) {
      width: 18.75rem;
    }
    @media only screen and (max-width: $bp-smallest) {
      width: 18.75rem;
    }
  }
  &_masonry {
    width: auto;
    height: auto;
    @media only screen and (max-width: $bp-small) {
      width: 100%;
      // left: 100px;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #00000080;
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.font {
  &_mammoth {
    font-size: 5rem;
  }
  &_gigantic {
    font-size: 4.375rem;
  }
  &_huge {
    font-size: 3.75rem;
  }
  &_largest {
    font-size: 38px;
  }
  &_large {
    font-size: 32px;
  }
  &_medium {
    font-size: 28px;
  }
  &_small {
    font-size: 20px;
  }
  &_smallest {
    font-size: 16px;
  }
  &_tiny {
    font-size: 10px;
  }
}

.fontweight {
  &_bolder {
    font-weight: 800;
  }
  &_bold {
    font-weight: 600;
  }
  &_regular {
    font-weight: 400;
  }
  &_thin {
    font-weight: 100;
  }
}
.display {
  &_row {
    display: flex;
    flex-direction: row;
  }
  &_column {
    display: flex;
    flex-direction: column;
  }
}

.align_items {
  &_center {
    align-items: center;
  }
}

.padding {
  &_top {
    &_mammoth {
      padding-top: 50px;
    }
    &_gigantic {
      padding-top: 35px;
    }
    &_huge {
      padding-top: 30px;
    }
    &_largest {
      padding-top: 25px;
    }
    &_large {
      padding-top: 20px;
    }
    &_medium {
      padding-top: 15px;
    }
    &_small {
      padding-top: 10px;
    }
    &_smallest {
      padding-top: 5px;
    }
    &_tiny {
      padding-top: 2px;
    }
  }
}
