.masonry {
  &_listitem {
    margin-right: 60px;
    margin-bottom: 40px;
    border: 15px solid #00000020;
    border-radius: 10px;
    list-style: none;
    @media only screen and (max-width: $bp-gigantic) {
      margin-right: 60px;
    }
    @media only screen and (max-width: $bp-largest) {
      margin-right: 10px;
    }
    @media only screen and (max-width: $bp-large) {
      margin-right: 10px;
    }
    @media only screen and (max-width: $bp-medium) {
      margin-right: 40px;
    }
    @media only screen and (max-width: $bp-small) {
      margin-right: 10px;
    }
  }
  &_container {
    overflow-y: auto;
    height: 100%;
    padding-left: 120px;
    padding-bottom: 30px;
    &_top {
      padding-top: 100px;
      padding-left: 120px;
      @media only screen and (max-width: $bp-large) {
        padding-left: 30px;
        //   padding-top: 0px;
      }
      @media only screen and (max-width: $bp-medium) {
        padding-left: 20px;
        //   padding-top: 100px;
      }
      @media only screen and (max-width: $bp-small) {
        //   padding-top: 100px;
        padding-left: 5px;
      }
    }
  }
}
