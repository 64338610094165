.ti {
  &_container {
    display: flex;
    flex-direction: column;
    //  max-width: 600px;
    margin-bottom: 20px;
    margin-right: 50px;
  }
  &_field {
    height: 35px;
    border-radius: 3px;
    border: solid 0.7px #aeadad50;
    padding-left: 10px;
    width: 200px;
    font-size: 14px;
  }
  &_textarea {
    height: 7.5rem;
    border-radius: 3px;
    border: solid 0.7px #aeadad50;
    padding-left: 10px;
    padding-top: 10px;
    //  max-width: 450px;
    width: 100%;
    font-size: 14px;
  }
  &_label {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
